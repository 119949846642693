import { useWorkflow } from '@/common/workflowEngine/useWorkflow';
import { crud } from '@/common/crud';
import { BN_EncounterType, HealthcareService, Questionnaire } from '@/fhirworks';
import { $httpFhirApi } from '@/common/api/httpFhir.service';
import { v4 as uuidv4 } from 'uuid';
import { getUrlFromCanonical } from '@/common/core';
import { useAuthStore } from '@/stores/auth';

// const findQuestionnaireItem = function (obj, prop, value) {
//     let returnObj = null;
//     obj.item.forEach((i, index) => {
//         if (returnObj) return;
//         else if (i[prop] === value) {
//             returnObj = i;
//             if (returnObj) {
//                 returnObj.temp.parent = obj;
//                 returnObj.temp.index = index;
//             }
//         } else if (i.item.length) {
//             returnObj = findQuestionnaireItem(i, prop, value);
//             if (returnObj) {
//                 returnObj.temp.parent = i;
//             }
//         }
//     });
//     return returnObj;
// };

async function importProfile(data, existingTransaction = false) {
    let transaction = existingTransaction || [];

    if (!data.resource?.id || data.resource?.resourceType !== 'HealthcareService') return;
    /**
     * Import a managed program for the first time
     */

    let programToImport = new HealthcareService(data.resource.toJSON());

    // remove forms that are deprecated
    if (data.deprecatedFormUrls) {
        programToImport.form = programToImport.form.filter((form) => !data.deprecatedFormUrls.includes(form));
    }

    // if importing a custom profile.  set derivedFrom and remove id of the custom profile.
    if (!data.add) {
        programToImport = new HealthcareService({ name: data.resource.name, location: data.resource.location, active: true, isProgram: true });
        programToImport.derivedFrom = data.resource.url;
    }

    /**
     * Since forms may be imported as part of an EncounterType we don't want to import them again
     * if defined under the Program. After processing the encounter types we will create an array of
     * newly created forms and if they are also part of the Program definition then we will insert
     * the new form url into the program form array.
     */
    const encounterTypes = [];

    let loopTransaction = transaction;
    for (const url of data.resource.encounterType) {
        const response = await importEncounterType({ url: url, deprecatedFormUrls: data.deprecatedFormUrls }, loopTransaction);

        loopTransaction = response.transaction;
        if (response.encounterTypeUrl) encounterTypes.push(response.encounterTypeUrl);
    }

    programToImport.encounterType = encounterTypes;

    transaction.push(crud.post(programToImport));

    if (existingTransaction === false) {
        const { postTransaction } = useWorkflow();
        return await postTransaction(transaction);
    }

    return transaction;
}

async function importEncounterType(data, existingTransaction = false) {
    let transaction = existingTransaction || [];
    /**
     * Import an encounter type for the first time
     */
    let encounterType;
    if (data.resource) {
        encounterType = data.resource.toJSON();
    } else if (data.url) {
        encounterType = (await $httpFhirApi.get(data.url)).data?.toJSON();
    }
    if (!encounterType?.id || encounterType?.resourceType !== 'BN_EncounterType') return { transaction: transaction };

    // remove forms that are deprecated
    if (data.deprecatedFormUrls) {
        encounterType.form = encounterType.form?.filter((form) => !data.deprecatedFormUrls.includes(form.url));
    }

    const local = await $httpFhirApi.get('/BN_EncounterType?url=' + encounterType.url);
    if (local.data?.length) {
        return { transaction: transaction, encounterTypeUrl: local.data?.[0]?.url };
    }

    if (encounterType.form?.length) {
        for (const form of encounterType.form) {
            if (!form.url) return;
            await importForm({ url: form.url, copy: true }, transaction);
        }
    }
    // create the new resource
    const newUrl = 'met-' + uuidv4();
    const resource = new BN_EncounterType({ ...encounterType, agency: null, id: newUrl });
    // set url, agency, derivedFrom, status
    const authStore = useAuthStore();
    resource.url = import.meta.env.VITE_PROTOCOL + authStore.account.fhirApiUri + '/BN_EncounterType/' + newUrl;
    resource.derivedFrom = encounterType.url;
    resource.status = 'active';

    transaction.push(crud.post(resource));

    if (existingTransaction === false) {
        const { postTransaction } = useWorkflow();
        return await postTransaction(transaction);
    }

    return { transaction: transaction, encounterTypeUrl: resource.url };
}

async function importForm(data, existingTransaction = false) {
    let transaction = existingTransaction || [];

    // only import if it doesn't already exist otherwise return url of the most recent active existing form
    if (!data.copy) {
        const imported = await $httpFhirApi.get('/Questionnaire?_sort=-version&_count=1&derivedFrom=' + data.url);
        if (imported.data?.length) {
            return { transaction: [], response: { existing: true, url: imported.data?.[0]?.url } };
        }
    }

    // Create a new Questionnaire resource based on the managedForm
    const [profileServer] = data.url.split('/Questionnaire/');
    let managedForm = await $httpFhirApi.get(profileServer + '/Questionnaire?_sort=-version&_count=1&status=active&url=' + data.url);

    managedForm = managedForm.data?.[0];

    if (!managedForm?.id || managedForm?.resourceType !== 'Questionnaire') return [];

    // create the new resource and merge the contents
    const resource = new Questionnaire({ id: 'mf-' + uuidv4(), title: managedForm.title, formType: managedForm.formType, groupNote: managedForm.groupNote });
    // set bnOwner, derivedFrom, url, status, version
    resource.derivedFrom = [managedForm.url];
    const authStore = useAuthStore();
    resource.url = import.meta.env.VITE_PROTOCOL + authStore.account.fhirApiUri + '/Questionnaire/' + resource.id;
    resource.status = 'active';
    if (data.copy) {
        resource.title = resource.title + ' - COPY';
    }

    // save new imported questionnaire
    transaction.push(crud.post(resource));

    if (existingTransaction === false) {
        const { postTransaction } = useWorkflow();
        return await postTransaction(transaction);
    }

    return transaction;
}

async function getProfiledQuestionnaires(options = '') {
    // @TODO.  Update this way of getting used Questionnaires when pulling in Questionnaires
    let [hcs, questionnaires] = await Promise.all([
        $httpFhirApi.get('HealthcareService?derivedFrom:missing=false&_elements=derivedFrom'),
        $httpFhirApi.get('Questionnaire?derivedFrom:missing=false&_elements=derivedFrom'),
    ]);
    hcs = hcs.data;
    questionnaires = questionnaires.data;

    let additionalQuery = '';
    if (options.query) {
        additionalQuery = options.query;
    }

    let hcsPromises = hcs.map((h) => $httpFhirApi.get(h.derivedFrom));
    let hcsReturns = await Promise.all(hcsPromises);

    hcsReturns = hcsReturns.flatMap((ret) => ret.data);
    let formUrls = hcsReturns.flatMap((hcs) => hcs.form);
    let hcsJoinedUrls = formUrls.map((url) => getUrlFromCanonical(url).url).filter((url) => url);

    let joinedQuestionnaires = questionnaires
        .flatMap((q) => q.derivedFrom)
        .map((url) => getUrlFromCanonical(url).url)
        .filter((url) => url);

    if (!hcsJoinedUrls.length && !joinedQuestionnaires.length) return [];

    let allUrls = [...hcsJoinedUrls, ...joinedQuestionnaires];

    let urlBase = getUrlFromCanonical(allUrls[0]).urlBase;

    let query = { url: urlBase + '?url=' + allUrls.join(',') + '&status=active' + additionalQuery };

    return [query];
}

export function useProfileFlow() {
    return { importProfile, importEncounterType, getProfiledQuestionnaires, importForm };
}
