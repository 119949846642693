import merge from 'lodash/merge';
import Reference from './types/Reference';
import BackboneElement from '@/fhirworks/BackboneElement';

export default class BN_EncounterTypeForm extends BackboneElement {
    static __className = 'BN_EncounterTypeForm';

    __objectStructure = {
        service: Reference,
        url: String,
    };

    __objectDefaults = {
        experimental: false,
    };

    constructor(constructJson, className = 'BN_EncounterTypeForm') {
        super(constructJson, className);
        this.createAndPopulateStructure(this.__objectStructure, constructJson, this.__objectDefaults);
        this.originalObjJson = this.toJSON();
    }

    toJSON() {
        return merge(super.toJSON(this), this.getJsonForStructure(this.__objectStructure));
    }
}
