import { $httpFhirApi } from '@/common/api/httpFhir.service';
import _ from 'lodash';

import { Patient } from '@/fhirworks';
import { $http } from '@/common/api/http.service';
import { useAuthStore } from '@/stores/auth';

import { usePatientAccess } from '@/composables/usePatientAccess';

function getTransactionQuery(queryString) {
    queryString = encodeURI(queryString.split(' ').filter(Boolean).join('+'));

    return 'Patient/$lookup?by=name.family,name.given,birthDate,identifier.value;telecom.value&sort=name.given,name.family&count=50&&q=' + queryString;
}

async function processPatientResponse(res, enforceAccess = true) {
    let returnedPatients = [];
    if (!res) return returnedPatients;

    res.entry?.forEach((resultRow) => {
        let patient = new Patient(resultRow.resource);
        patient._group = _.get(resultRow, '_group', '');

        returnedPatients.push(patient);
    });

    if (enforceAccess) {
        // get list of id's
        let idList = res.entry.map((el) => el.resource.id).join(',');
        let ret = await $httpFhirApi.get('Encounter?_elements=status,healthcareService,subject,location&part-of:missing=true&episode-of-care:missing=false&subject=' + idList);

        const { addProgramsToContacts } = usePatientAccess();
        addProgramsToContacts(returnedPatients, ret.data);
    }

    return returnedPatients;
}

export default {
    getTransactionQuery: getTransactionQuery,
    processPatientResponse: processPatientResponse,
    inviteToPortal: (subject, config) => {
        const authStore = useAuthStore();
        if (!['Patient'].includes(subject.resourceType)) {
            throw new Error("Only Patient's and Person's can be portal users");
        }

        return $http.post(
            '/api/account/' + authStore.account.id + '/invite-portal-user',
            {
                subjectType: subject.resourceType,
                subjectId: subject.id,
                email: subject.primaryEmailValue,
            },
            config,
        );
    },
    sendPatientPacketEmail: (subject, regarding, config) => {
        const authStore = useAuthStore();
        const regardingNameSplit = regarding.name.split(' ');
        const regardingName = regardingNameSplit[0] + ' ' + regardingNameSplit[1][0];
        const subjectNameSplit = subject.name.split(' ');
        const subjectName = subjectNameSplit[0] + ' ' + subjectNameSplit[1][0];
        if (!['Patient'].includes(subject.resourceType)) {
            throw new Error('Not a patient');
        }

        return $http.post(
            '/api/account/' + authStore.account.id + '/send-portal-packet-email',
            {
                subjectType: subject.resourceType,
                subjectId: subject.id,
                subjectName: subjectName,
                email: subject.email,
                regardingName: regardingName,
                portalUpdate: subject.portalUpdate,
            },
            config,
        );
    },
};
