import fhirpath from 'fhirpath';
import { Bundle, DomainResource } from '@/fhirworks';
import { format, parseISO, addDays } from 'date-fns';
import { $httpFhirApi } from '@/common/api/httpFhir.service';
import { useAuthStore } from '@/stores/auth';
import isEmpty from 'lodash/isEmpty';

/**
 * For any special fields that need to query for data, the resourceType property is required
 * Also for special data retrieval use the callback property and omit the path property
 */

const specialVariableDateMap = [
    { type: 'date', label: 'Current date', token: '{XDate.Today}', path: 'today' },
    { type: 'date', label: 'Current year', token: '{XDate.CurrentYear}', dateFormat: 'yyyy' },
    { type: 'date', label: 'In 7 days', token: '{XDate.+7}', path: 7 },
    { type: 'date', label: 'In 30 days', token: '{XDate.+30}', path: 30 },
];

const specialVariablePatientMap = [
    { type: 'fhir', label: 'Full name', token: '{XPatient.FullName}', path: 'fullName', resourceType: 'Patient' },
    { type: 'fhir', label: 'First name', token: '{XPatient.FirstName}', path: 'firstName', resourceType: 'Patient' },
    { type: 'fhir', label: 'First name (phonetic)', token: '{XPatient.FirstNamePhonetic}', path: 'givenPhoneticSpelling', resourceType: 'Patient' },
    { type: 'fhir', label: 'Preferred name', token: '{XPatient.PreferredName}', path: 'preferredName', resourceType: 'Patient' },
    { type: 'fhir', label: 'Maiden or prior name', token: '{XPatient.MaidenName}', path: 'maidenName', resourceType: 'Patient' },
    { type: 'fhir', label: 'Middle name', token: '{XPatient.MiddleName}', path: 'middleName', resourceType: 'Patient' },
    { type: 'fhir', label: 'Last name', token: '{XPatient.LastName}', path: 'lastName', resourceType: 'Patient' },
    { type: 'fhir', label: 'Medical record number', token: '{XPatient.MRN}', path: 'mrn', resourceType: 'Patient' },
    { type: 'fhir', label: 'Birth date', token: '{XPatient.BirthDate}', path: 'birthDate', resourceType: 'Patient', format: 'date' },
    { type: 'fhir', label: 'Age', token: '{XPatient.Age}', path: 'age', resourceType: 'Patient' },
    { type: 'fhir', label: 'Home phone', token: '{XPatient.HomePhone}', path: 'homePhoneValue', resourceType: 'Patient' },
    { type: 'fhir', label: 'Work phone', token: '{XPatient.WorkPhone}', path: 'workPhoneValue', resourceType: 'Patient' },
    { type: 'fhir', label: 'Mobile phone', token: '{XPatient.MobilePhone}', path: 'mobilePhoneValue', resourceType: 'Patient' },
    { type: 'fhir', label: 'Personal email', token: '{XPatient.PersonalEmail}', path: 'homeEmailValue', resourceType: 'Patient', forceCase: 'lower' },
    { type: 'fhir', label: 'Work email', token: '{XPatient.WorkEmail}', path: 'workEmailValue', resourceType: 'Patient', forceCase: 'lower' },
    { type: 'fhir', label: 'Home address', token: '{XPatient.HomeAddress}', path: 'homeAddressValue', resourceType: 'Patient' },
    { type: 'fhir', label: 'Work address', token: '{XPatient.WorkAddress}', path: 'workAddressValue', resourceType: 'Patient' },
    { type: 'fhir', label: 'Mailing address', token: '{XPatient.MailingAddress}', path: 'billingAddressValue', resourceType: 'Patient' },
    { type: 'fhir', label: 'County', token: '{XPatient.County}', path: 'homeAddress.district', resourceType: 'Patient' },
    { type: 'fhir', label: 'Time zone', token: '{XPatient.TimeZone}', path: 'timeZone', resourceType: 'Patient' },
    { type: 'fhir', label: 'Sex', token: '{XPatient.Sex}', path: 'gender', resourceType: 'Patient', transform: 'case' },
    { type: 'fhir', label: 'Gender identity', token: '{XPatient.GenderIdentity}', path: 'genderIdentity.coding.display', resourceType: 'Patient', transform: 'case' },
    { type: 'fhir', label: 'Pronouns', token: '{XPatient.Pronouns}', path: 'pronouns.display', resourceType: 'Patient', transform: 'case' },
    { type: 'pronoun', key: 'subject', label: 'He/She', token: '{XPatient.HeShe}', path: 'pronouns', resourceType: 'Patient', transform: 'case' },
    { type: 'pronoun', key: 'object', label: 'Him/Her', token: '{XPatient.HimHer}', path: 'pronouns', resourceType: 'Patient', transform: 'case' },
    { type: 'pronoun', key: 'possessive', label: 'His/Her', token: '{XPatient.HisHer}', path: 'pronouns', resourceType: 'Patient', transform: 'case' },
    { type: 'pronoun', key: 'reflexive', label: 'Himself/Herself', token: '{XPatient.HimHerSelf}', path: 'pronouns', resourceType: 'Patient', transform: 'case' },
    { type: 'fhir', label: 'Race', token: '{XPatient.Race}', path: 'race', resourceType: 'Patient' },
    { type: 'fhir', label: 'Ethnicity', token: '{XPatient.Ethnicity}', path: 'ethnicity', resourceType: 'Patient' },
    { type: 'fhir', label: 'Marital status', token: '{XPatient.MaritalStatus}', path: 'maritalStatus.coding.display', resourceType: 'Patient', transform: 'case' },
    { type: 'fhir', label: 'Preferred language', token: '{XPatient.PreferredLanguage}', path: 'communication.language.coding.display', resourceType: 'Patient' },
    { type: 'fhir', label: 'Social security number', token: '{XPatient.SocialSecurityNumber}', path: 'ssn', resourceType: 'Patient' },
    { type: 'fhir', label: 'Driver license number', token: '{XPatient.DriverLicenseNumber}', path: 'driversLicense', resourceType: 'Patient' },
    { type: 'fhir', label: 'Custom identifier', token: '{XPatient.CustomIdentifier}', path: 'customIdentifier', resourceType: 'Patient' },
    { type: 'fhir', label: 'Religion', token: '{XPatient.Religion}', path: 'religion', resourceType: 'Patient' },
    { type: 'fhir', label: 'Employer and occupation or school', token: '{XPatient.EmployerSchool}', path: 'employerSchool', resourceType: 'Patient' },
    { type: 'fhir', label: 'Helpful comments', token: '{XPatient.HelpfulComments}', path: 'keyComments', resourceType: 'Patient' },
    {
        type: 'fhir',
        label: 'Next appointment',
        token: '{XPatient.NextAppt}',
        resourceType: 'Appointment',
        callback: (bundle) => {
            let nextAppt = bundle.entry?.length ? bundle.entry?.find((e) => e.resourceType === 'Appointment')?.start : bundle?.start;
            if (!nextAppt) return 'No future appointment scheduled.';
            return formatVariable(nextAppt, { format: 'dateTime' });
        },
    },
    {
        type: 'fhir',
        label: 'Next appointment (verbose)',
        token: '{XPatient.NextApptVerbose}',
        resourceType: 'Appointment',
        callback: (bundle) => {
            let nextAppt = bundle.entry?.length ? bundle.entry.find((e) => e.resourceType === 'Appointment')?.start : bundle?.start;
            if (!nextAppt) return 'No future appointment scheduled.';
            return formatVariable(nextAppt, { format: 'dateVerbose' });
        },
    },
    {
        type: 'fhir',
        label: 'Next appointment same type',
        token: '{XPatient.NextApptSameType}',
        resourceType: 'AppointmentSame',
        callback: (bundle) => {
            let nextAppt = bundle.entry?.length ? bundle.entry?.find((e) => e.resourceType === 'Appointment' && e.bnAppointmentType)?.start : bundle?.bnAppointmentType ? bundle?.start : undefined;
            if (!nextAppt) return 'No future appointment of the same type scheduled.';
            return formatVariable(nextAppt, { format: 'dateTime' });
        },
    },
    {
        type: 'fhir',
        label: 'Next appointment same type (verbose)',
        token: '{XPatient.NextApptSameTypeVerbose}',
        resourceType: 'AppointmentSame',
        callback: (bundle) => {
            let nextAppt = bundle.entry?.length ? bundle.entry?.find((e) => e.resourceType === 'Appointment' && e.bnAppointmentType)?.start : bundle?.bnAppointmentType ? bundle?.start : undefined;
            if (!nextAppt) return 'No future appointment of the same type scheduled.';
            return formatVariable(nextAppt, { format: 'dateVerbose' });
        },
    },
    { type: 'fhir', label: 'Episode start date', token: '{XEpisodeOfCare.start}', path: 'period.start', resourceType: 'EpisodeOfCare', format: 'date' },
    { type: 'fhir', label: 'Estimated episode start date', token: '{XEpisodeOfCare.estimatedStart}', path: 'estPeriod.start', resourceType: 'EpisodeOfCare', format: 'date' },
    { type: 'fhir', label: 'Episode discharge date', token: '{XEpisodeOfCare.end}', path: 'period.end', resourceType: 'EpisodeOfCare', format: 'date' },
    { type: 'fhir', label: 'Estimated episode discharge date', token: '{XEpisodeOfCare.estimatedEnd}', path: 'estPeriod.end', resourceType: 'EpisodeOfCare', format: 'date' },
];

const specialVariableProviderMap = [
    { type: 'fhir', label: 'First name', token: '{XProvider.FirstName}', path: 'firstName', resourceType: 'Practitioner' },
    { type: 'fhir', label: 'Last name', token: '{XProvider.LastName}', path: 'lastName', resourceType: 'Practitioner' },
    { type: 'fhir', label: 'Full name', token: '{XProvider.FullName}', path: 'fullName', resourceType: 'Practitioner' },
    { type: 'fhir', label: 'Work phone', token: '{XProvider.WorkPhone}', path: 'workPhoneValue', resourceType: 'Practitioner' },
    { type: 'fhir', label: 'Work email', token: '{XProvider.WorkEmail}', path: 'workEmailValue', resourceType: 'Practitioner', forceCase: 'lower' },
    { type: 'fhir', label: 'Work address', token: '{XProvider.WorkAddress}', path: 'workAddressValue', resourceType: 'Practitioner' },
];

const specialVariableCompanyMap = [
    { type: 'session', label: 'Company name', token: '{XCompany.companyName}', path: 'companyName' },
    { type: 'session', label: 'Current user', token: '{XUser.fullName}', path: 'fullName' },
];

const specialVariableCareTeamMap = [
    {
        type: 'fhir',
        label: 'Coordinator',
        token: '{XEpisodeOfCare.coordinator}',
        resourceType: 'EpisodeOfCare',
        callback: (bundle) => {
            return bundle.entry.find((e) => e.resourceType === 'Practitioner')?.fullName;
        },
    },
    {
        type: 'fhir',
        label: 'Primary provider',
        token: '{XCareTeam.primaryProvider}',
        resourceType: 'CareTeam',
        callback: (bundle) => {
            let careTeam = bundle.entry.find((e) => e.resourceType === 'CareTeam');
            let primaryProviderReference = fhirpath.evaluate(careTeam, "participant.where(role.coding.code='405279007').member")[0];
            return primaryProviderReference ? bundle.entry.find((e) => e.id === primaryProviderReference.id)?.fullName : '';
        },
    },
];

const specialVariableMap = [
    { header: 'Date intervals' },
    ...specialVariableDateMap,
    { header: 'Patient' },
    ...specialVariablePatientMap,
    { header: 'Provider' },
    ...specialVariableProviderMap,
    { header: 'Company' },
    ...specialVariableCompanyMap,
    { header: 'Care team' },
    ...specialVariableCareTeamMap,
];

export const SPECIAL_VARIABLES = specialVariableMap;
export const SPECIAL_VARIABLE_DATE = specialVariableDateMap;
export const SPECIAL_VARIABLE_PATIENT = specialVariablePatientMap;
export const SPECIAL_VARIABLE_PROVIDER = specialVariableProviderMap;
export const SPECIAL_VARIABLE_COMPANY = specialVariableCompanyMap;
export const SPECIAL_VARIABLE_CARE_TEAM = specialVariableCareTeamMap;

const pronounMap = [
    {
        gender: 'unknown',
        subject: 'he/she',
        object: 'him/her',
        possessive: 'his/her',
        reflexive: 'himself/herself',
    },
    {
        gender: 'male',
        subject: 'he',
        object: 'him',
        possessive: 'his',
        reflexive: 'himself',
    },
    {
        gender: 'female',
        subject: 'she',
        object: 'her',
        possessive: 'her',
        reflexive: 'herself',
    },
    {
        gender: 'other',
        subject: 'they',
        object: 'them',
        possessive: 'their',
        reflexive: 'themselves',
    },
    {
        gender: 'LA29518-0',
        subject: 'he',
        object: 'him',
        possessive: 'his',
        reflexive: 'himself',
    },
    {
        gender: 'LA29519-8',
        subject: 'she',
        object: 'her',
        possessive: 'her',
        reflexive: 'herself',
    },
    {
        gender: 'LA29520-6',
        subject: 'they',
        object: 'them',
        possessive: 'their',
        reflexive: 'themselves',
    },
    {
        gender: 'LA29523-0',
        subject: 'ze',
        object: 'zir',
        possessive: 'zirs',
        reflexive: 'zirself',
    },
    {
        gender: 'LA29521-4',
        subject: 'xie',
        object: 'hir',
        possessive: 'hir',
        reflexive: 'hirself',
    },
    {
        gender: 'LA29515-6',
        subject: 'co',
        object: 'co',
        possessive: 'cos',
        reflexive: 'coself',
    },
    {
        gender: 'LA29516-4',
        subject: 'en',
        object: 'en',
        possessive: 'ens',
        reflexive: 'enself',
    },
    {
        gender: 'LA29517-2',
        subject: 'ey',
        object: 'em',
        possessive: 'eir',
        reflexive: 'emself',
    },
    {
        gender: 'LA29522-2',
        subject: 'yo',
        object: 'yo',
        possessive: 'yos',
        reflexive: 'yoself',
    },
    {
        gender: 'LA29524-8',
        subject: 've',
        object: 'vis',
        possessive: 'ver',
        reflexive: 'verself',
    },
];

const genderIdentityMap = [
    {
        code: 'unknown',
        gender: 'unknown',
    },
    {
        code: '446151000124109',
        gender: 'male',
    },
    {
        code: '446141000124107',
        gender: 'female',
    },
    {
        code: '407377005',
        gender: 'male',
    },
    {
        code: '407376001',
        gender: 'female',
    },
    {
        code: '446131000124102',
        gender: 'other',
    },
    {
        code: 'OTH',
        gender: 'other',
    },
    {
        code: 'LA29518-0',
        gender: 'LA29518-0',
    },
    {
        code: 'LA29519-8',
        gender: 'LA29519-8',
    },
    {
        code: 'LA29520-6',
        gender: 'LA29520-6',
    },
    {
        code: 'LA29523-0',
        gender: 'LA29523-0',
    },
    {
        code: 'LA29521-4',
        gender: 'LA29521-4',
    },
    {
        code: 'LA29515-6',
        gender: 'LA29515-6',
    },
    {
        code: 'LA29516-4',
        gender: 'LA29516-4',
    },
    {
        code: 'LA29517-2',
        gender: 'LA29517-2',
    },
    {
        code: 'LA29522-2',
        gender: 'LA29522-2',
    },
    {
        code: 'LA29524-8',
        gender: 'LA29524-8',
    },
];

function getPronoun(genderIdentityCode, pronounType) {
    // Find the matching gender category in the genderIdentityMap
    const matchedGender = genderIdentityMap.find((g) => g.code === genderIdentityCode);

    // If a matching gender is found, use it to find the pronoun
    if (matchedGender) {
        const matchedPronoun = pronounMap.find((p) => p.gender === matchedGender.gender);
        if (matchedPronoun) {
            return matchedPronoun[pronounType];
        }
    }

    // If no matching gender is found, return a default pronoun
    return 'they';
}

function parsePronounVariable(value, variable, resource) {
    let pronounGender = 'unknown';

    // **Refactored to prioritize finding the pronoun property on the resource first**
    const pronouns = fhirpath.evaluate(resource, `${variable.resourceType}.pronouns`)[0];
    if (pronouns?.code) {
        pronounGender = pronouns.code;
    } else {
        // If no pronouns, check for gender identity
        const genderIdentity = fhirpath.evaluate(resource, `${variable.resourceType}.genderIdentity`)[0];
        if (genderIdentity?.coding?.[0]?.code) {
            pronounGender = genderIdentity.coding[0].code;
        } else {
            // If no gender identity, use gender
            const gender = fhirpath.evaluate(resource, `${variable.resourceType}.gender`)[0];
            if (gender) {
                pronounGender = gender;
            }
        }
    }

    // Use the getPronoun function to retrieve the pronoun
    const finalPronoun = getPronoun(pronounGender, variable.key);
    return finalPronoun;
}

function extractVariables(str) {
    let variables = [];
    if (!str) return variables;
    const tokens = [...str.matchAll(/{[xX]([^}]*)}/g)];
    tokens.forEach((token) => {
        let variable = specialVariableMap.find((specialVariable) => specialVariable.token?.toUpperCase() === token?.[0]?.toUpperCase());
        // dynamic variables
        if (!variable) {
            if (token[0]?.toUpperCase().includes('XDATE.+')) {
                const path = parseInt(token[1].split('+')[1]);
                variable = { token: token[0], path: path };
            } else {
                return;
            }
        }
        // set target variable string
        variable.target = token[0];
        // set case
        variable.case = variable.forceCase ? variable.forceCase : token[0].substring(1, 2) === 'X' ? 'upper' : 'lower';
        // add variable to be parsed
        variables.push({ ...variable });
    });
    return variables;
}

function formatVariable(value, variable) {
    // Default to '' if there are any error in format/parse
    const authStore = useAuthStore();
    let spaceIndex, time;
    try {
        switch (variable.format) {
            case 'date':
                value = format(parseISO(value), authStore?.outputDateFormat || 'MM/dd/yyyy');
                break;
            case 'dateTime':
                value = format(parseISO(value), (authStore?.outputDateFormat || 'MM/dd/yyyy') + authStore.outputTimeFormat);
                spaceIndex = value.indexOf(' ');
                value = value.substring(0, spaceIndex) + ' at ' + value.substring(spaceIndex + 1);
                break;
            case 'dateVerbose':
                time = format(parseISO(value), (authStore?.outputDateFormat || 'MM/dd/yyyy') + authStore.outputTimeFormat);
                spaceIndex = time.indexOf(' ');
                value = format(parseISO(value), 'PPPP') + ' at ' + time.substring(spaceIndex + 1);
                break;
            default:
                value = '';
        }
    } catch (e) {
        value = '';
    }
    return value;
}

function parseDateVariable(path, dateFormat = null) {
    const authStore = useAuthStore();

    dateFormat = dateFormat || authStore?.outputDateFormat || 'MM/dd/yyyy';

    // default to current date
    let date = new Date();
    // current time/date
    if (path === 'now') dateFormat += ' h:mm a';
    // numeric path means add that number of days to current date (+7 days, +30 days, etc.)
    else if (Number.isInteger(path)) date = addDays(date, path);
    // format and return date string
    return format(date, dateFormat);
}

function parseSessionVariable(variable) {
    const authStore = useAuthStore();
    if (variable.token.includes('XCompany')) {
        if (variable.path === 'companyName') return authStore?.account.name;
    } else if (variable.token.includes('XUser')) {
        if (variable.path === 'fullName') return authStore?.practitioner.fullName;
    }
}

function parseOtherVariable() {
    // placeholder for future use
    return;
}

export default {
    replace(str, data, isGroupArea = false) {
        const variables = extractVariables(str);
        if (!variables.length && isEmpty(data)) return str;

        // replace variables
        variables.forEach((variable) => {
            let value;
            let resourceIndex = data?.indexLibrary?.[variable?.resourceType];
            let resource = data?.fhirData?.[resourceIndex?.toString()];

            // if we are in a group area of a group note, only parse date, session, and Practitioner variables
            if (isGroupArea && !['Practitioner'].includes(variable?.resourceType) && !['session', 'date'].includes(variable?.type)) {
                str = str.replace(variable.target, '');
            }

            if (resource) {
                if (variable.path) {
                    if (resource instanceof Bundle) {
                        resource = resource.entry?.find((e) => e?.resourceType === variable?.resourceType);
                    }
                    value = fhirpath.evaluate(resource, variable.resourceType + '.' + variable.path)[0];
                } else if (variable.callback) {
                    value = variable.callback(resource);
                }

                // objects
                if (typeof value === 'object') {
                    value = value.text || '';
                }
                // pronouns
                if (variable.type === 'pronoun') {
                    value = parsePronounVariable(value, variable, resource);
                }
            } else {
                // variable is pointing to another source (date, etc.)
                if (variable.type === 'date') {
                    value = parseDateVariable(variable.path, variable.dateFormat);
                } else if (variable.type === 'session') {
                    value = parseSessionVariable(variable);
                } else {
                    value = parseOtherVariable(variable);
                }
            }
            // format the variable
            if (variable.format) {
                value = formatVariable(value, variable);
            }

            if (value && typeof value !== 'string') {
                value = value.toString();
            }

            // change case
            if (variable.case === 'upper' && typeof value === 'string') {
                value = value?.charAt(0).toUpperCase() + value?.slice(1);
            } else if (variable.case === 'lower' && typeof value === 'string') {
                value = value?.charAt(0).toLowerCase() + value?.slice(1);
            }
            // replace variable
            const variableValue = value || '[' + variable.label + ']';
            str = str.replace(variable.target, variableValue);
        });
        return str;
    },
    async resourceFetch(data, context) {
        let resourceArray = [];

        // get resources from the value passed in
        data.map((e) => {
            extractVariables(e.value).flatMap((e) => {
                if (!e?.resourceType) return [];
                else resourceArray.push(e?.resourceType);
            });
        });

        // generate a unique list of resources for query and clean up any undefined entries
        let uniqueResources = [...new Set(resourceArray)];

        let transaction = { type: 'transaction', entry: [] };
        let currentIndex = 0;
        let indexLibrary = {};

        let patientResource;
        let apptResource;
        let pracResource;

        for (const item of uniqueResources) {
            let queryParam = '';
            //assess context
            switch (item) {
                case 'Appointment':
                    // I don't think we need this because we only have special vars for the next appt, not the current one
                    // if (context.appointment instanceof DomainResource) {
                    //     apptResource = context.appointment;
                    //     continue;
                    // }

                    if (context.patient && context.patient.id) {
                        let todayDate = parseDateVariable(null, 'yyyy-MM-dd');
                        queryParam = 'Appointment?patient=' + context.patient.id + '&date=ge' + todayDate + '&status=pending&_sort=date&_count=1';
                    }
                    break;
                case 'AppointmentSame':
                    if (context.patient && context.patient.id) {
                        // Query for NextApptSameType appointment.  Prefer to use the appointment
                        // if it is passed, otherwise look for the encounter information

                        let todayDate = parseDateVariable(null, 'yyyy-MM-dd');
                        if (context.appointment && context.appointment.bnAppointmentType) {
                            let bnApptType = '&bnAppointmentType=' + context.appointment.bnAppointmentType.id;
                            queryParam = 'Appointment?patient=' + context.patient.id + bnApptType + '&date=ge' + todayDate + '&status=pending&_sort=date&_count=1';
                        } else if (context.encounter) {
                            context.encounter.type?.find((item) => {
                                if (item.coding?.[0]?.system === 'http://fhir.bestnotes.com/encounterTypeUrl') {
                                    let bnApptType = '&bnAppointmentType=' + item.coding?.[0].code.split('/').at(-1);
                                    queryParam = 'Appointment?patient=' + context.patient.id + bnApptType + '&date=ge' + todayDate + '&status=pending&_sort=date&_count=1';
                                }
                            });
                        }
                    }
                    break;
                case 'Patient':
                    if (context.patient instanceof DomainResource) {
                        patientResource = context.patient;
                        continue;
                    }

                    if (context.patient && context.patient.id) {
                        queryParam = item + '/' + context.patient.id;
                    }
                    break;
                case 'Practitioner':
                    if (context.provider instanceof DomainResource) {
                        pracResource = context.provider;
                        continue;
                    }

                    if (context.provider && context.provider.id && context.provider.resourceType === 'Practitioner') {
                        queryParam = item + '/' + context.provider.id;
                    }
                    break;
                case 'EpisodeOfCare':
                    if (context.episodeOfCare) {
                        queryParam = item + '?id=' + context.episodeOfCare.id + '&_include=care-manager:Practitioner';
                    }
                    break;
                case 'CareTeam':
                    if (context.episodeOfCare) {
                        queryParam =
                            'EpisodeOfCare?id=' +
                            context.episodeOfCare.id +
                            '&_include=team:CareTeam&_include=CareTeam:participant:Practitioner&_elements=Practitioner.name,Practitioner.primaryLocation,EpisodeOfCare.id,CareTeam.participant';
                    }
                    break;
            }

            if (queryParam) {
                transaction.entry.push({ request: { method: 'GET', url: queryParam } });
                indexLibrary[item.toString()] = currentIndex++;
            }
        }
        let fhirData = [];
        if (transaction.entry.length) {
            fhirData = (await $httpFhirApi.post('/', transaction)).data;
        }

        if (patientResource) {
            fhirData.push(patientResource);
            indexLibrary['Patient'] = currentIndex++;
        }

        if (pracResource) {
            fhirData.push(pracResource);
            indexLibrary['Practitioner'] = currentIndex++;
        }

        if (apptResource) {
            fhirData.push(apptResource);
            indexLibrary['Appointment'] = currentIndex++;
        }

        if (fhirData.length) {
            return { fhirData, indexLibrary };
        } else {
            return {};
        }
    },
};
