<script setup>
import BnToolbarBtn from '@/components/BnToolbarBtn.vue';
import BnLoading from '@/components/BnLoading.vue';
import BnContactEditOrganization from '@/components/contacts/BnContactEditOrganization.vue';
import BnContactEditHuman from '@/components/contacts/BnContactEditHuman.vue';
import DataProvider from '@/components/DataProvider.js';
import BnInfo from '@/components/BnInfo.vue';
import BnMenu from '@/components/BnMenu.vue';
import BnMenuItem from '@/components/BnMenuItem.vue';
import { Patient, Practitioner, Organization } from '@/fhirworks';
import createHuman from '@/common/createHuman';
import { isPerson } from '@/common/core';
import BnFooter from '@/components/BnFooter';
import { useNotificationStore } from '@/stores/notification';
import { useEpisodeOfCareFlow } from '@/common/workflowEngine/useEpisodeOfCareFlow';
import { computed, onMounted, ref } from 'vue';

const props = defineProps({
    authStore: Object,
    isWidget: Boolean,
});
const emit = defineEmits(['dataChanged', 'updateWidget']);
const dialog = ref(false);
const resolve = ref(null);
const reject = ref(null);
const options = ref(null);
const baseOptions = ref({
    header: 'BestNotes',
    width: 900,
    persistent: true,
    info: undefined,
    warning: undefined,
});
const localProps = ref(undefined);
const dpContact = ref(undefined);
const validResource = ref(false);
const contactTypes = ref({
    patient: 'patient',
    practitioner: 'professional',
    organization: 'organization',
});
const loading = ref(false);
const accessStatus = ref(null);
const isPatientOrContact = ref(null);
onMounted(() => {
    contactTypes.value.patient = props.authStore?.clientVocab?.toLowerCase() || contactTypes.value?.patient.toLowerCase();
});
const existingContact = computed(() => {
    return dpContact.value?.id;
});
const header = computed(() => {
    const action = dpContact.value?.id ? 'Edit' : 'Add';
    const target = isPerson(dpContact.value) ? 'contact' : contactTypes.value?.[dpContact.value?.resourceType?.toLowerCase()];
    return target ? action + ' ' + target : '';
});
const dpContactQuery = computed(() => {
    if (localProps.value?.contact) {
        return { query: { resourceType: localProps.value?.contact?.resourceType, id: localProps.value?.contact?.id } };
    }
    // add new contact
    else if (localProps.value.add) {
        let newContact;

        if (localProps.value.add.type === 'organization') {
            newContact = new Organization();
        } else if (localProps.value.add.type === 'practitioner') {
            newContact = new Practitioner();
        } else if (localProps.value.add.type === 'patient') {
            newContact = new Patient({
                managingOrganization: {
                    id: props.authStore.organizationAccount.id,
                    resourceType: 'Organization',
                },
            });
            newContact.addMrn(props.authStore.account.fhirApiUri);
        } else if (localProps.value.add.type === 'contact') {
            newContact = new Patient();
            newContact.addMrn(props.authStore.account.fhirApiUri);
        }
        // populate data based on search query
        createHuman(localProps.value.add.value, newContact);
        return { resource: newContact };
    }
    return {};
});
const checkAccess = function (val) {
    accessStatus.value = val;
    return accessStatus.value;
};
const contactType = function (val) {
    isPatientOrContact.value = val;
    return isPatientOrContact.value;
};
const show = async function (functionOptions, functionProps) {
    localProps.value = functionProps;
    // set header
    options.value = { ...baseOptions.value, ...functionOptions };
    // display dialog
    dialog.value = true;
    return new Promise((localResolve, localReject) => {
        resolve.value = localResolve;
        reject.value = localReject;
    });
};
const hide = function () {
    dialog.value = false;
};
const accountNotification = function (contact) {
    const notificationStore = useNotificationStore();
    if (!isPatientOrContact.value && contact === 'Patient') {
        contact = 'Contact';
    } else if (contact === 'Practitioner') {
        contact = 'Professional';
    }
    notificationStore.add({
        message: `${contact} saved successfully.`,
        color: 'info',
    });
};
const dpContactRef = ref(null);
const contactEditHumanRef = ref(null);
const contactEditOrganizationRef = ref(null);
const save = async function () {
    // dont allow multiple saves
    if (loading.value === true) return;
    loading.value = true;
    const newPatientEOC = !dpContact.value.id && dpContact.value.resourceType === 'Patient' ? dpContact.value.temp : undefined;
    await dpContactRef.value?.save();

    // new Patient - create initial Episode of Care
    if (newPatientEOC?.program?.id) {
        const { createEpisodeOfCare } = useEpisodeOfCareFlow();
        await createEpisodeOfCare({
            patient: dpContact.value,
            complaint: newPatientEOC.complaint || 'Unknown',
            placementStatus: newPatientEOC.placementStatus,
            statusReason: newPatientEOC.statusReason,
            program: newPatientEOC.program,
            location: newPatientEOC.location,
            status: newPatientEOC.status,
            managingOrganization: newPatientEOC.location.managingOrganization,
            Guarantor: {
                display: 'Self',
            },
            estPeriod: {
                start: newPatientEOC.eocEstStartDate,
            },
        });
    }

    if (localProps.value.addNewRelatedContact) {
        // Save the new relationship value
        let contactEditForm = contactEditHumanRef.value;
        if (dpContact.value.resourceType === 'Organization') {
            contactEditForm = contactEditOrganizationRef.value;
        }
        await contactEditForm.saveRelationship(dpContact.value);
        emit('dataChanged');
    }

    if (props.isWidget) {
        emit('updateWidget', dpContact.value);
    }

    let contactType = dpContact.value.resourceType;

    if (!accessStatus.value && accessStatus.value !== null) {
        accountNotification(contactType);
        dialog.value = false;
        loading.value = false;
        return;
    }
    // send notification
    accountNotification(contactType);
    //return the dpContact
    return close(dpContact.value);
};
const close = function (resolveLocal) {
    resolve.value(resolveLocal);
    dialog.value = false;
    options.value = undefined;
    loading.value = false;
};
const validateResource = function (inValid) {
    validResource.value = !inValid;
};
defineExpose({ show });
</script>

<template>
    <div>
        <v-dialog v-if="options" v-model="dialog" scrollable :fullscreen="$vuetify.display.xs" :width="options.width" :persistent="options.persistent">
            <v-card flat class="d-flex flex-column">
                <div class="flex-grow-0">
                    <ion-header>
                        <ion-toolbar>
                            <v-toolbar density="compact" flat>
                                <v-toolbar-title data-cy="editContactDialogHeader">{{ header }}</v-toolbar-title>
                                <bn-toolbar-btn
                                    type="text"
                                    :icon="existingContact ? 'times' : 'trash'"
                                    :label="existingContact ? 'Close' : 'Discard'"
                                    @click="close"
                                    data-cy="contactEditDialogCloseBtn"
                                />
                                <bn-toolbar-btn
                                    type="outlined"
                                    right
                                    color="primary"
                                    :icon="loading ? 'spinner' : 'check'"
                                    label="Save"
                                    :disabled="!validResource || loading"
                                    @click="save"
                                    data-cy="saveContactButton"
                                />
                                <bn-menu v-if="$vuetify.display.xs" offset-y left show-usersnap-option>
                                    <bn-menu-item icon="check" label="Save" :disabled="!validResource" @click="save" />
                                    <bn-menu-item icon="times" :label="existingContact ? 'Close' : 'Discard'" @click="close" />
                                </bn-menu>
                            </v-toolbar>
                        </ion-toolbar>
                    </ion-header>
                    <v-divider></v-divider>
                    <!-- Info/Warning message -->
                    <bn-info v-if="options.info" type="outlined">{{ options.info }}</bn-info>
                    <bn-info v-if="options.warning" type="outlined" color="warning">{{ options.warning }}</bn-info>
                </div>
                <data-provider ref="dpContactRef" v-model="dpContact" v-bind="dpContactQuery">
                    <template #loading>
                        <bn-loading card skeleton="article@3" class="pa-4" />
                    </template>
                    <template #default>
                        <div data-cy="newRelatedContactInfo" class="pa-4 scroll-on">
                            <bn-contact-edit-organization
                                v-if="dpContact.resourceType === 'Organization'"
                                ref="contactEditOrganizationRef"
                                :resource="dpContact"
                                :relationship-props="localProps"
                                :auth-store="authStore"
                                @validate="validateResource"
                            />
                            <bn-contact-edit-human
                                v-else
                                ref="contactEditHumanRef"
                                :resource="dpContact"
                                :relationship-props="localProps"
                                :auth-store="authStore"
                                :is-widget="isWidget"
                                @show-access="checkAccess"
                                @validate="validateResource"
                                @is-patient-or-contact="contactType"
                            />
                        </div>
                    </template>
                </data-provider>
                <!-- ios ample touch footer bar -->
                <bn-footer class="dialog-footer"></bn-footer>
            </v-card>
        </v-dialog>
    </div>
</template>
