import axios from 'axios';
import axiosRetry from 'axios-retry';

import _ from 'lodash';
import { useAuthStore } from '@/stores/auth';

// Terminology source JSON files
import administrativeGenderResponseJsonFile from '@/assets/json/administrativeGender.json';
import ethnicityResponseJsonFile from '@/assets/json/ethnicity.json';
import ethnicityDetailResponseJsonFile from '@/assets/json/ethnicityDetail.json';
import genderIdentityResponseJsonFile from '@/assets/json/genderIdentity.json';
import maritalStatusResponseJsonFile from '@/assets/json/maritalStatus.json';
import languagesResponseJsonFile from '@/assets/json/languages.json';
import pronounsResponseJsonFile from '@/assets/json/pronouns.json';
import raceResponseJsonFile from '@/assets/json/race.json';
import relationshipTypesResponseJsonFile from '@/assets/json/relationshipTypes.json';
import relationshipTypesInverse from '@/assets/json/relationshipTypesInverse.json';
import religiousAffiliationResponseJsonFile from '@/assets/json/religiousAffiliation.json';
import sexualOrientationResponseJsonFile from '@/assets/json/sexualOrientation.json';

export const $httpTerminology = axios.create({
    baseURL: import.meta.env.VITE_TERMINOLOGY_URL || 'https://terminology.fhir.bestnotesfhir.com',
    headers: {
        post: { Accept: 'application/json' },
        // cache terminology requests for 2 weeks, this shouldn't change much and doesn't contain ePHI
        get: { Accept: 'application/json', 'cache-control': 'max-age=1209600,private' },
        put: { Accept: 'application/json' },
        patch: { Accept: 'application/json' },
    },
});

if (import.meta.env.VITE_TERMINOLOGY_LOCAL) {
    // Add a request interceptor
    $httpTerminology.interceptors.request.use(
        (config) => {
            const authStore = useAuthStore();
            if (authStore.token) {
                config.headers.setAuthorization('Bearer ' + authStore.token);
            }

            config.baseURL = import.meta.env.VITE_PROTOCOL + _.get(authStore, 'account.fhirApiUri', '');

            return config;
        },
        (err) => {
            // Do something with request error
            return Promise.reject(err);
        },
    );
}

axiosRetry($httpTerminology, { retries: 3 });

export async function administrativeGender(server = false) {
    let response = administrativeGenderResponseJsonFile;
    if (server) {
        response = await $httpTerminology.get('Concept?valueset=administrative-gender');
    }
    return response.data.entry;
}

export async function religiousAffiliation(server = false) {
    let response = religiousAffiliationResponseJsonFile;
    if (server) {
        response = await $httpTerminology.get('Concept?valueset=v3-ReligiousAffiliation&_sort=display');
    }
    return response.data.entry;
}

export async function ethnicity(server = false) {
    let response = ethnicityResponseJsonFile;
    if (server) {
        response = await $httpTerminology.get('Concept?valueset=v3-Ethnicity&_sort=display');
    }
    return response.data.entry;
}

export async function ethnicityDetail(server = false) {
    let response = ethnicityDetailResponseJsonFile;
    if (server) {
        response = await $httpTerminology.get('Concept?valueset=v3-Ethnicity&hierarchy=2135-2,2186-5&_sort=display');
    }
    return response.data.entry;
}

export async function race(server = false) {
    let response = raceResponseJsonFile;
    if (server) {
        response = await $httpTerminology.get('Concept?valueset=v3-Race&_sort=display');
    }
    return response.data.entry;
}

export async function sexualOrientation(server = false) {
    let response = sexualOrientationResponseJsonFile;
    if (server) {
        response = await $httpTerminology.get('Concept?system=http://fhir.bestnotes.com/sexual-orientation-codes');
    }
    return response.data.entry;
}

export async function genderIdentity(server = false) {
    let response = genderIdentityResponseJsonFile;
    if (server) {
        response = await $httpTerminology.get('Concept?system=http://fhir.bestnotes.com/gender-identity-codes');
    }
    return response.data.entry;
}

export async function maritalStatus(server = false) {
    let response = maritalStatusResponseJsonFile;
    if (server) {
        response = await $httpTerminology.get('Concept?system=http://terminology.hl7.org/CodeSystem/v3-MaritalStatus');
        // If query was changed to look for the system value equal to
        // http://terminology.hl7.org/CodeSystem/v3-NullFlavor so that the unknown is found
        // when deduping it does not use the item with the display of 'unknown'
        response.data.entry.push({
            resource: {
                id: 'hl7-CodeSystem-v3-NullFlavor-UNK',
                code: 'UNK',
                system: 'http://terminology.hl7.org/CodeSystem/v3-MaritalStatus',
                display: 'unknown',
                valueset: 'v3-MaritalStatus',
                definition: 'A proper value is applicable, but not known.',
                resourceType: 'Concept',
            },
        });
    }
    return response.data.entry;
}

export async function pronouns(server = false) {
    let response = pronounsResponseJsonFile;
    if (server) {
        response = await $httpTerminology.get('Concept?system=http://fhir.bestnotes.com/pronoun-codes');
    }
    return response.data.entry;
}

export async function languages(server = false) {
    let response = languagesResponseJsonFile;
    if (server) {
        response = await $httpTerminology.get('Concept?system=http://fhir.bestnotes.com/language-codes');
    }
    return response.data.entry;
}

export async function getGenderDisplay(gender) {
    let genderResources = await administrativeGender();
    return genderResources.map((g) => g.resource).find((o) => o.code === gender)?.display;
}

export async function getRelationshipTypes(server = false) {
    let response = relationshipTypesResponseJsonFile;
    if (server) {
        response = await $httpTerminology.get('Concept?valueset=relatedperson-relationshiptype');
    }
    return response.data.entry;
}

export async function getFamilyMemberTypes() {
    let response = await $httpTerminology.get('Concept?valueset=http://cts.nlm.nih.gov/fhir/ValueSet/2.16.840.1.113883.1.11.19563');
    return response.data.entry;
}

export async function getRelationshipTypesInverse() {
    return relationshipTypesInverse;
}

export function findOrganizationRelationshipList(thisContact, relatedContact) {
    if (thisContact === 'Organization' && relatedContact !== 'Organization') {
        return [
            { display: 'Contractor', code: 'BN_CONTRACTOR', definition: 'Related Person is contractor to organization' },
            { display: 'Employee', code: 'BN_EMPLOYEE', definition: 'Related Person is employee to organization' },
            { display: 'Other', code: 'BN_OTHER', definition: 'Related Person is other relationship to organization' },
        ];
    }

    if (thisContact !== 'Organization' && relatedContact === 'Organization') {
        const authStore = useAuthStore();
        return [
            // Employer is also used in the normal terminology service so leaving this relationship alone
            { display: 'Employer', code: 'E', definition: 'Related Person is employer to ' + authStore.clientVocab.toLowerCase() },
            { display: 'Provider', code: 'BN_PROVIDER', definition: 'Related Person is provider to ' + authStore.clientVocab.toLowerCase() },
            { display: 'Other', code: 'BN_OTHER', definition: 'Related Person is other relationship to ' + authStore.clientVocab.toLowerCase() },
        ];
    }

    if (thisContact === 'Organization' && relatedContact === 'Organization') {
        return [
            { display: 'Business Associate', code: 'BN_BUSINESS_ASSOCIATE', definition: 'Related Organization has a business relationship to organization' },
            { display: 'Parent Company', code: 'BN_PARENT_COMPANY', definition: 'Related Organization is a parent company to organization' },
            { display: 'Subsidiary', code: 'BN_SUBSIDIARY', definition: 'Related Organization is a subsidiary to this organization' },
            { display: 'Other', code: 'BN_OTHER', definition: 'Related Person is other relationship to organization' },
        ];
    }
}
// todo: configure terminology server for jwt
// Add a request interceptor
// $httpTerminology.interceptors.request.use(
//     config => {
//         if (authStore.token) {
//             config.headers.common['Authorization'] = 'Bearer ' + authStore.token;
//         }
//         return config;
//     },
//     err => {
//         // Do something with request error
//         return Promise.reject(err);
//     },
// );
